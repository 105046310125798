@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: "MarkPro-Bold !important";
    font-weight: 600;
    src: "/fonts/MarkPro-Bold.otf" format("otf");
  }
  @font-face {
    font-family: "MarkPro-Medium !important";
    font-weight: 500;
    src: "/fonts/MarkPro-Medium.otf" format("otf");
  }
  @font-face {
    font-family: "MarkPro-Book !important";
    font-weight: 400;
    src: "/fonts/MarkPro-Book.otf" format("otf");
  }

}

html {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

root {
  height: 100vh;
}